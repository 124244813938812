exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-collaboration-js": () => import("./../../../src/pages/collaboration.js" /* webpackChunkName: "component---src-pages-collaboration-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/articlePage.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/projectPage.js" /* webpackChunkName: "component---src-templates-project-page-js" */)
}

